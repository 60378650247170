export default {
  prefooter: {
    strumenti: [
      {
        title: "VeriMat PLUS",
        href: "https://verimatplus.deascuola.it/",
        logo: "",
      },
      {
        title: "VeriTest PLUS e VeriTest",
        href: "https://veritestplus.deascuola.it/",
        logo: "",
      },
      {
        title: "CreaTest PLUS",
        href: "https://createstplus.deascuola.it/",
        logo: "",
      },
      {
        title: "Percorso INVALSI",
        href: "https://invalsi.deascuola.it/",
        logo: "",
      },
      {
        title: "Test d'ingresso",
        href: "https://test-di-ingresso.deascuola.it/",
        logo: "",
      },
      {
        title: "Esame di Stato",
        href: "https://esame-di-stato.deascuola.it/",
        logo: "",
      },
      {
        title: "Educazione civica",
        href: "https://educazionecivica.deascuola.it/",
        logo: "",
      },
    ],
    portaliDisciplinari: [
      {
        title: "Zona Arte",
        href: "https://zonaarte.deascuola.it/",
        logo: "",
      },
      {
        title: "Zona Geografia",
        href: "https://zonageografia.deascuola.it/",
        logo: "",
      },
      {
        title: "Zona Italiano",
        href: "https://zonaitaliano.deascuola.it/",
        logo: "",
      },
      {
        title: "Zona Matematica",
        href: "https://zonamatematica.deascuola.it/",
        logo: "",
      },
      {
        title: "Zona Musica",
        href: "https://zonamusica.deascuola.it/",
        logo: "",
      },
      {
        title: "Zona Religione",
        href: "https://zonareligione.deascuola.it/",
        logo: "",
      },
      {
        title: "Zona Scienze",
        href: "https://zonascienze.deascuola.it/",
        logo: "",
      },
      {
        title: "Zona Scienze Motorie",
        href: "https://zonascienzemotorie.deascuola.it/",
        logo: "",
      },
    ],
    seguiDeaScuola: [
      {
        title: "DLive",
        href: "https://blog.deascuola.it/",
        logo: "logo/social/DLive.png",
      },
      {
        title: "Facebook",
        href: "https://www.facebook.com/Deascuola",
        logo: "logo/social/Facebook.png",
      },
      {
        title: "Linkedin",
        href: "https://www.linkedin.com/company/deascuola",
        logo: "logo/social/LinkedIN.png",
      },
      {
        title: "Instagram",
        href: "https://www.instagram.com/deascuola/",
        logo: "logo/social/Instagram.png",
      },
      {
        title: "Twitter",
        href: "https://twitter.com/DeAScuola",
        logo: "logo/social/Twitter.png",
      },
      {
        title: "Youtube",
        href: "https://www.youtube.com/DeascuolaChannel",
        logo: "logo/social/Youtube.png",
      },
    ],
    partnersLogos: [
      {
        title: "",
        href: "",
        logo: "logo/partners/black-cat.svg",
      },
      {
        title: "",
        href: "",
        logo: "logo/partners/cedam-scuola.svg",
      },
      {
        title: "",
        href: "",
        logo: "logo/partners/cideb.svg",
      },
      {
        title: "",
        href: "",
        logo: "logo/partners/deagostini.svg",
      },
      {
        title: "",
        href: "",
        logo: "logo/partners/marietti-scuola.svg",
      },

      {
        title: "",
        href: "",
        logo: "logo/partners/garzanti-linguistica.svg",
      },

      {
        title: "",
        href: "",
        logo: "logo/partners/garzanti-scuola.svg",
      },
      {
        title: "",
        href: "",
        logo: "logo/partners/ghisetti-corvi.svg",
      },
      {
        title: "",
        href: "",
        logo: "logo/partners/liviana.svg",
      },
      {
        title: "",
        href: "",
        logo: "logo/partners/petrini.svg",
      },
      {
        title: "",
        href: "",
        logo: "logo/partners/valmartina.svg",
      },
    ],
    university: [
      {
        title: "Utet Università",
        href: "http://www.utetuniversita.it/",
        logo: "logo/university/utet.svg",
      },
      {
        title: "Isedi",
        href: "http://www.isedi.it/",
        logo: "logo/university/isedi.svg",
      },
      {
        title: "Città Studi",
        href: "http://www.cittastudi.it/",
        logo: "logo/university/citta-studi.svg",
      },
    ],
  },
  footer: {
    sectionTitles: {
      servizio: "SERVIZIO CLIENTI",
      info: "INFO",
      contatti: "CONTATTI",
      iNostriSiti: "I NOSTRI SITI",
      terminiGenerali: "TERMINI GENERALI",
      copyright: "Tutti i diritti riservati © Copyright",
    },
    email: "info@deaformazione.it",
    info: [
      {
        title: "Chi siamo",
        href: "https://deascuola.it/chi-siamo/",
        logo: "",
      },
      {
        title: "I nostri brand",
        href: "https://deascuola.it/i-nostri-brand/",
        logo: "",
      },
      {
        title: "Gruppo Mondadori",
        href: "https://www.gruppomondadori.it/",
        logo: "",
      },
    ],
    contatti: [
      {
        title: "Assistenza",
        href: "https://deascuola.it/assistenza/",
        logo: "",
      },
      {
        title: "Rete commerciale",
        href: "https://deascuola.it/rete-commerciale/",
        logo: "",
      },
      {
        title: "Education Consultant",
        href: "https://deascuola.it/d-scuola-educational-consultants-elt/",
        logo: "",
      },
      {
        title: "Formazione",
        href: "https://formazione.deascuola.it/faq-e-contatti/",
        logo: "",
      },
      {
        title: "Ufficio Estero",
        href: "https://deascuola.it/ufficio-estero/",
        logo: "",
      },
    ],
    iNostriSiti: [
      {
        title: "Deascuola",
        href: "https://deascuola.it/",
        logo: "",
      },
      {
        title: "Formazione",
        href: "https://formazione.deascuola.it/",
        logo: "",
      },
      {
        title: "BlackCat Cideb",
        href: "https://www.blackcat-cideb.com/",
        logo: "",
      },
      {
        title: "Garzanti Linguistica",
        href: "https://www.garzantilinguistica.it/",
        logo: "",
      },
      {
        title: "Utet Università",
        href: "http://www.utetuniversita.it/",
        logo: "",
      },
      {
        title: "Isedi",
        href: "http://www.isedi.it/",
        logo: "",
      },
      {
        title: "Città Studi",
        href: "http://www.cittastudi.it/",
        logo: "",
      },
    ],
    terminiGenerali: [
      {
        title: "Governance",
        href: "https://deascuola.it/governance",
        logo: "",
      },
      {
        title: "Condizioni generali di contratto",
        href: "https://formazione.deascuola.it/condizioni-generali-di-contratto/",
        logo: "",
      },
      {
        title: "Informativa privacy",
        href: "https://digital.mondadori.it/privacy/informativasiti/dscuolainformativapiattaforma.html",
        logo: "",
      },
      {
        title: "Privacy Policy",
        href: "https://www.gruppomondadori.it/privacy-policy",
        logo: "",
      },
      {
        title: "Cookies Policy",
        href: "https://www.iubenda.com/privacy-policy/65798487/cookie-policy",
        logo: "",
      },
      {
        title: "Preferenze Cookies",
        href: "",
        logo: "",
        class: "iubenda-advertising-preferences-link",
      },
    ],
  },
};
