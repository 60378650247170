export default {
  prefooter: {},
  footer: {
    sectionTitles: {
      servizio: "SERVIZIO CLIENTI",
      info: "INFO",
      contatti: "CONTATTI",
      iNostriSiti: "I NOSTRI SITI",
      terminiGenerali: "TERMINI GENERALI",
      copyright: "Tutti i diritti riservati © Copyright",
    },
    info: [
      {
        title: "Chi siamo",
        href: "https://deascuola.it/chi-siamo/",
        logo: "",
      },
      {
        title: "I nostri brand",
        href: "https://deascuola.it/i-nostri-brand/",
        logo: "",
      },
      {
        title: "Gruppo Mondadori",
        href: "https://www.gruppomondadori.it/",
        logo: "",
      },
    ],
    contatti: [
      {
        title: "Assistenza",
        href: "http://www.utetuniversita.it/contattaci",
        logo: "",
      },
    ],
    iNostriSiti: [
      {
        title: "Deascuola",
        href: "https://deascuola.it/",
        logo: "",
      },
      {
        title: "Formazione",
        href: "https://formazione.deascuola.it/",
        logo: "",
      },
      {
        title: "BlackCat Cideb",
        href: "https://www.blackcat-cideb.com/",
        logo: "",
      },
      {
        title: "Garzanti Linguistica",
        href: "https://www.garzantilinguistica.it/",
        logo: "",
      },
      {
        title: "Utet Università",
        href: "http://www.utetuniversita.it/",
        logo: "",
      },
      {
        title: "Isedi",
        href: "http://www.isedi.it/",
        logo: "",
      },
      {
        title: "Città Studi",
        href: "http://www.cittastudi.it/",
        logo: "",
      },
    ],
    terminiGenerali: [
      {
        title: "Governance",
        href: "https://deascuola.it/governance",
        logo: "",
      },
      {
        title: "Condizioni generali di contratto",
        href: "https://deascuola.it/condizioni-generali-di-contratto-siti/",
        logo: "",
      },
      {
        title: "Informativa privacy",
        href: "https://digital.mondadori.it/privacy/informativasiti/dscuolainformativapiattaforma.html",
        logo: "",
      },
      {
        title: "Privacy Policy",
        href: "https://www.gruppomondadori.it/privacy-policy",
        logo: "",
      },
      {
        title: "Cookies Policy",
        href: "https://www.iubenda.com/privacy-policy/65798487/cookie-policy",
        logo: "",
      },
      {
        title: "Preferenze Cookies",
        href: "",
        logo: "",
        class: "iubenda-advertising-preferences-link",
      },
    ],
  },
};
