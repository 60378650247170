<template>
  <div
    id="footer-app"
    :data-variant="variant"
    :data-source="source"
    :data-skin="skin"
    :data-popunder="showPopUnder"
    :data-language="language"
  >
    <Prefooter v-if="showPrefooter" :info="info.prefooter" />
    <Footer :class="skinClass" :info="info.footer" :show-piva="showPiva" />
    <PopUnder v-if="showPopUnder" :info="info" />
  </div>
</template>

<script>
import Footer from "./components/Footer";
import PopUnder from "./components/PopUnder";
import Prefooter from "./components/Prefooter";
import footerDeascuola from "./footerDeascuola";
import footerFormazione from "./footerFormazione";
import footerBlackcat from "./footerBlackcat";
import footerBlackcatEn from "./footerBlackcatEn";
import footerGarzanti from "./footerGarzanti";
import footerUniversita from "./footerUniversita";
import popunderEn from "./popunderEn";
import popunderIt from "./popunderIt";

export default {
  name: "App",
  components: {
    Footer,
    PopUnder,
    Prefooter,
  },
  data() {
    return {
      info: { footer: {}, prefooter: {} },
      showPrefooter: true,
      showPopUnder: true,
      variant: "",
      skin: "",
      source: "",
      skinClass: "dea-footer--deascuola",
      showPiva: true,
      language: "it",
    };
  },
  created() {
    this.setValue(this.$root.$el?.dataset);
  },
  updated() {
    this.setValue(this.$root.$el?.dataset);
  },
  methods: {
    setValue(dataset) {
      this.variant = dataset?.variant;
      this.source = dataset?.source;
      this.skin = dataset?.skin;
      this.popunder = dataset?.popunder;
      this.language = dataset?.language;

      if (this.variant === "noprefooter") {
        this.showPrefooter = false;
      }
      if (this.popunder === "false") {
        this.showPopUnder = false;
      }
      if (this.source === "deascuola") {
        this.$set(this, "info", footerDeascuola);
      } else if (this.source === "formazione") {
        this.$set(this, "info", footerFormazione);
      } else if (this.source === "garzanti") {
        this.$set(this, "info", footerGarzanti);
      } else if (this.source === "blackcat") {
        if (this.language === "en") {
          this.$set(this, "info", footerBlackcatEn);
        } else {
          this.$set(this, "info", footerBlackcat);
        }
        this.showPiva = false;
      } else if (this.source === "universita") {
        this.$set(this, "info", footerUniversita);
        this.showPiva = false;
      }
      if (this.skin === "garzanti") {
        this.skinClass = "dea-footer--garzanti";
      } else if (this.skin === "blackcat") {
        this.skinClass = "dea-footer--blackcat";
      }
      if (this.language === "en") {
        this.info.popunder = popunderEn;
      } else {
        this.info.popunder = popunderIt;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/system.utils";
@import "./styles/mixins";

body {
  margin: 0;
  position: relative;
}

.dea-footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__container {
    @include media(">=m") {
      width: $bp-size-large;
    }

    @include media("<m") {
      width: $bp-size-small-medium;
    }

    @include media("<=sm") {
      width: 100%;
    }

    @include media("<=xs") {
      grid-auto-flow: row;
    }

    &--small {
      @include media("<=m") {
        width: calc(100% - #{$gutter});
      }

      @include media("<=s") {
        grid-template-rows: repeat(2, min-content);
      }

      width: $content-limit;
    }

    &--copyright {
      @include media("<=m") {
        width: calc(100% - #{$gutter});
      }
      margin-top: $space-base;

      .dea-footer__container--small {
        @include media("<=m") {
          width: calc(100% - #{$gutter});
        }
      }
    }

    column-gap: 3rem;
    display: grid;
    grid-auto-flow: column;
    margin-top: $space-x-small;
    width: 100%;
  }

  &__footer-top {
    background-color: $dea-color-grey-light;
    padding-top: $gutter;

    .dea-footer__links {
      & + .dea-footer__links {
        @include media("<=xs") {
          margin-top: $gutter;
        }
      }

      &-title {
        border-left: $border-light $dea-color-grey-medium-light;
        color: $dea-color-black;
      }

      &-list {
        @include bp-small {
          grid-template-rows: repeat(10, min-content);
        }

        @include bp-large {
          grid-template-rows: repeat(4, min-content);
        }

        &-item {
          a {
            color: $dea-color-black;
          }
        }
      }
    }
  }

  &__footer-bottom {
    background-color: $dea-color-grey-medium-dark;
    padding-top: $gutter;

    &-info {
      @include media(">=m") {
        min-width: 180px;
      }

      @include media("<=xs") {
        padding-left: $gutter / 3;
      }

      &-title {
        @include media("<=m") {
          padding-left: 0;
        }
        color: $dea-color-white;
        font-family: $font-primary-medium;
        font-size: $h-font-size-xx-small;
        line-height: 1.25;
        margin: 0;
        margin-bottom: $gutter-quarter;
        padding-left: $gutter-half;
      }

      &-text {
        @include media("<=m") {
          padding-left: 0;
        }
        color: $dea-color-white;
        font-family: $font-primary-light;
        font-size: $h-font-size-x-small;
        margin: 0;
        margin-bottom: $gutter-three-quarter;
        padding-left: $gutter-half;

        a {
          color: $dea-color-white;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }

    .dea-footer__container {
      @include media("<=s") {
        column-gap: 2rem;
      }
    }

    .dea-footer__links {
      &-title {
        @include media("<=xs") {
          margin-top: $gutter;
        }

        border-left: $border-light $dea-color-grey-medium-light;
        color: $dea-color-white;
      }

      &-list {
        @include media(">=s") {
          grid-template-rows: repeat(9, min-content);
        }

        @include media("<s") {
          grid-template-rows: repeat(10, min-content);
        }

        &-item {
          a {
            color: $dea-color-white;
          }
        }
      }
    }
  }

  &__links {
    &-title {
      @include media("<xs") {
        border-left: $border-light $dea-color-grey-medium-light;
      }

      @include media("<=xs") {
        border-left: 0;
      }

      font-family: $font-primary-medium;
      font-size: $h-font-size-xx-small;
      letter-spacing: $letter-spacing-large;
      line-height: 1.25;
      margin: 0;
      padding-bottom: $gutter-quarter;
      padding-left: $gutter / 3;
    }

    &-list {
      @include bp-small {
        border-bottom: $border-light $dea-color-grey-medium-light;
        grid-template-rows: repeat(10, min-content);
        margin-bottom: $gutter-half;
        padding-bottom: $gutter-half;
      }

      @include bp-small-medium {
        border-bottom: 0;
      }

      @include bp-large {
        grid-template-rows: repeat(9, min-content);
      }

      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 4rem;
      grid-template-rows: repeat(10, min-content);
      list-style: none;
      margin: 0;
      padding: 0;

      &-item {
        @include bp-small {
          border-left: 0;
        }

        @include bp-small-medium {
          border-left: $border-light $dea-color-grey-medium-light;
        }

        letter-spacing: $letter-spacing-base;
        line-height: 1.286;
        padding-bottom: $gutter-quarter;
        padding-left: $gutter / 3;

        a {
          font-family: $font-primary-light;
          font-size: $font-size-small;
          text-decoration: none;
        }

        img {
          margin-right: $gutter-quarter;
          vertical-align: middle;
          width: 20px;
        }
      }
    }
  }

  &__logos {
    @include media("<m") {
      width: calc(100% - #{$gutter});
    }

    @include media(">=m") {
      flex-wrap: nowrap;
      width: $content-limit;
    }

    border-top: $border-light $dea-color-grey-medium-light;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: $gutter-three-quarter;

    &-list {
      @include media("<m") {
        flex-wrap: wrap;
      }

      @include media(">=m") {
        flex-wrap: nowrap;
      }

      display: flex;
      justify-content: center;
      list-style: none;
      margin: $gutter-half 0;
      padding: 0;

      &-item {
        height: 50px;
        margin-right: $gutter-half;
        width: 50px;

        img {
          max-height: 50px;
          max-width: 50px;
        }
      }

      &--university {
        @include bp-small {
          border-left: 0;
          border-top: $border-light $dea-color-grey-medium-light;
        }

        @include bp-large {
          border-left: $border-light $dea-color-grey-medium-light;
          border-top: 0;
        }

        padding-left: $gutter-half;

        .dea-footer__logos-list-item {
          img {
            max-height: 50px;
            max-width: 50px;
          }
        }
      }
    }
  }

  &__copyright {
    border-top: $border-light $dea-color-grey-medium-light;
    color: $dea-color-white;
    font-family: $font-primary-light;
    font-size: $font-size-small;
    padding: $gutter-three-quarter 0;

    .dea-footer__container--small {
      margin: 0 auto;
    }

    p {
      color: $dea-color-white;
      margin: 0;
      padding-left: $gutter-half;
    }

    span {
      color: $dea-color-white;
      font-family: $font-primary-medium;
    }
  }

  &__popunder {
    @include media("<=s") {
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
    }
    background: $dea-color-white;
    border-left: $border-light $dea-color-grey-medium-light;
    border-radius: $gutter $gutter 0 0;
    border-right: $border-light $dea-color-grey-medium-light;
    border-top: $border-light $dea-color-grey-medium-light;
    bottom: 0;
    position: fixed;
    right: $gutter-quadruple;
    width: $popunder-width;
    z-index: 3;

    &-container {
      display: flex;
      flex-direction: column;
      padding: $gutter-double $space-s-large $space-s-large $space-s-large;
      position: relative;
    }

    &-close {
      @include dea-heading-medium-l;
      color: $dea-color-grey-medium;
      cursor: pointer;
      font-weight: bold;
      position: absolute;
      right: $gutter;
      top: 0;
    }

    &-text {
      @include dea-heading-light-s;
      margin-bottom: $space-base;
      margin-top: 0;
    }

    &-link {
      color: $dea-color-black;
      font-style: italic;
    }

    &-button {
      margin-left: auto;
    }
  }
}
</style>
