<template>
  <div class="dea-footer dea-footer__footer-top">
    <div class="dea-footer__container">
      <div class="dea-footer__links">
        <h4 class="dea-footer__links-title">STRUMENTI</h4>
        <ul class="dea-footer__links-list">
          <li v-for="(item, index) in info.strumenti" :key="`strumenti-${index}`" class="dea-footer__links-list-item">
            <a :href="item.href" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <div class="dea-footer__links">
        <h4 class="dea-footer__links-title">PORTALI DISCIPLINARI</h4>
        <ul class="dea-footer__links-list">
          <li
            v-for="(item, index) in info.portaliDisciplinari"
            :key="`portali-${index}`"
            class="dea-footer__links-list-item"
          >
            <a :href="item.href" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <div class="dea-footer__links">
        <h4 class="dea-footer__links-title">SEGUI DEASCUOLA</h4>
        <ul class="dea-footer__links-list no-border-bottom">
          <li v-for="(item, index) in info.seguiDeaScuola" :key="`segui-${index}`" class="dea-footer__links-list-item">
            <a :href="item.href" target="_blank"
              ><img :src="require(`../assets/${item.logo}`)" :alt="`Logo ${item.title}`" /> {{ item.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="dea-footer__logos">
      <ul class="dea-footer__logos-list">
        <li v-for="(item, index) in info.partnersLogos" :key="`partners-${index}`" class="dea-footer__logos-list-item">
          <img :src="require(`../assets/${item.logo}?data`)" :alt="`Logo ${item.title}`" />
        </li>
      </ul>

      <ul class="dea-footer__logos-list  dea-footer__logos-list--university">
        <li v-for="(item, index) in info.university" :key="`siti-${index}`" class="dea-footer__logos-list-item">
          <img :src="require(`../assets/${item.logo}?data`)" alt="Logo" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Prefooter",
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss"></style>
