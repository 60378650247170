export default {
  prefooter: {},
  footer: {
    sectionTitles: {
      servizio: "CUSTOMER CARE",
      info: "INFO",
      contatti: "KEEP IN TOUCH",
      iNostriSiti: "OUR WEBSITES",
      terminiGenerali: "TERMS AND CONDITIONS",
      copyright: "Tutti i diritti riservati © Copyright",
    },
    email: "info@blackcat-cideb.com",
    info: [
      {
        title: "About us",
        href: "https://deascuola.it/chi-siamo/",
        logo: "",
        target: "_blank",
      },
      {
        title: "Our brands",
        href: "https://deascuola.it/i-nostri-brand/",
        logo: "",
        target: "_blank",
      },
      {
        title: "Gruppo Mondadori",
        href: "https://www.mondadorigroup.com/",
        logo: "",
        target: "_blank",
      },
    ],
    contatti: [
      {
        title: "Contact us",
        href: "https://www.blackcat-cideb.com/en/contact-us/",
        logo: "",
      },
      {
        title: "Sales",
        href: "https://www.blackcat-cideb.com/en/sales",
        logo: "",
      },
    ],
    iNostriSiti: [
      {
        title: "Deascuola",
        href: "https://deascuola.it/",
        logo: "",
        target: "_blank",
      },
      {
        title: "Formazione",
        href: "https://formazione.deascuola.it/",
        logo: "",
        target: "_blank",
      },
      {
        title: "Deaflix",
        href: "https://deaflix.it/",
        logo: "",
        target: "_blank",
      },
      {
        title: "BlackCat Cideb",
        href: "https://www.blackcat-cideb.com/",
        logo: "",
        target: "_blank",
      },
      {
        title: "Garzanti Linguistica",
        href: "https://www.garzantilinguistica.it/",
        logo: "",
        target: "_blank",
      },
      {
        title: "Utet Università",
        href: "http://www.utetuniversita.it/",
        logo: "",
        target: "_blank",
      },
      {
        title: "Isedi",
        href: "http://www.isedi.it/",
        logo: "",
        target: "_blank",
      },
      {
        title: "Città Studi",
        href: "http://www.cittastudi.it/",
        logo: "",
        target: "_blank",
      },
    ],
    terminiGenerali: [
      {
        title: "Governance",
        href: "https://deascuola.it/governance",
        logo: "",
        target: "_blank",
      },
      {
        title: "Terms and Conditions of use",
        href: "https://www.blackcat-cideb.com/en/terms-and-conditions-of-use-2",
        logo: "",
        key: "general-terms",
      },
      {
        title: "Privacy Policy",
        href: "https://s3a.deascuola.it/05en_D_Scuola_informativa_siti-Informativa_Privacy_siti_web_ENGB.pdf",
        logo: "_blank",
      },
      {
        title: "Group Privacy Policy",
        href: "https://www.mondadorigroup.com/privacy-policy-eng",
        logo: "",
      },
      {
        title: "Cookies Policy",
        href: "https://www.iubenda.com/privacy-policy/86184209/cookie-policy",
        logo: "",
      },
      {
        title: "Cookies Preferences",
        href: "",
        logo: "",
        class: "iubenda-cs-preferences-link",
      },
    ],
  },
};
