<template>
  <div v-show="checkPopUnderVisibility()" class="dea-footer dea-footer__popunder">
    <div class="dea-footer__popunder-container">
      <p class="dea-footer__popunder-close" @click="closePopUnder()">&#x2715;</p>
      <p class="dea-footer__popunder-text">
        <span v-html="info.popunder.main"></span>
        <a
          target="_blank"
          class="dea-footer__popunder-link"
          href="https://digital.mondadori.it/privacy/informativasiti/dscuolainformativapiattaforma.html"
          >Privacy Policy</a
        >
        -
        <a target="_blank" class="dea-footer__popunder-link" :href="getCondizioniGeneraliUrl">{{
          info.popunder.generalTerms
        }}</a>
      </p>
      <button class="dea-button dea-button--small dea-footer__popunder-button" @click="setCookie()">
        <span class="dea-button__label">{{ info.popunder.cta }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopUnder",
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPopUnderVisible: true,
      infoTerminiGenerali: this.info.footer.terminiGenerali,
    };
  },
  computed: {
    getCondizioniGeneraliUrl() {
      if (this.infoTerminiGenerali !== undefined) {
        return this.infoTerminiGenerali.length > 0
          ? (this.infoTerminiGenerali.find(item => item.key && item.key === "generalTerms") || {}).href || ""
          : "";
      }
      return "";
    },
  },
  methods: {
    closePopUnder() {
      this.isPopUnderVisible = false;
    },
    checkPopUnderVisibility() {
      const cookie = this.$cookies.get("pop-under-accepted");
      return cookie ? false : this.isPopUnderVisible;
    },
    setCookie() {
      this.$cookies.set("pop-under-accepted", 1, "1y", null, "deascuola.it");
      this.closePopUnder();
    },
  },
};
</script>

<style lang="scss"></style>
