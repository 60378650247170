<template>
  <div class="dea-footer dea-footer__footer-bottom">
    <div class="dea-footer__container dea-footer__container--small">
      <div class="dea-footer__footer-bottom-info">
        <img
          v-if="showPiva"
          :src="require(`../assets/icons/deascuola.svg?data`)"
          alt="Deascuola Logo"
          class="dea-footer__footer-bottom-info-logo"
          loading="lazy"
        />
        <p v-if="showPiva" class="dea-footer__footer-bottom-info-text">P.IVA 01792180034</p>
        <h3 v-if="info.email" class="dea-footer__footer-bottom-info-title">{{ info.sectionTitles.servizio }}</h3>
        <p v-if="info.email" class="dea-footer__footer-bottom-info-text">
          <a :href="`mailto:${info.email}`">{{ info.email }}</a>
        </p>
      </div>
      <div class="dea-footer__links">
        <h4 class="dea-footer__links-title">{{ info.sectionTitles.info }}</h4>
        <ul class="dea-footer__links-list">
          <li v-for="(item, index) in info.info" :key="`info-${index}`" class="dea-footer__links-list-item">
            <a :href="item.href" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
      </div>

      <div class="dea-footer__links">
        <h4 class="dea-footer__links-title">{{ info.sectionTitles.contatti }}</h4>
        <ul class="dea-footer__links-list">
          <li v-for="(item, index) in info.contatti" :key="`info-${index}`" class="dea-footer__links-list-item">
            <a :href="item.href" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <div class="dea-footer__links">
        <h4 class="dea-footer__links-title">{{ info.sectionTitles.iNostriSiti }}</h4>
        <ul class="dea-footer__links-list">
          <li v-for="(item, index) in info.iNostriSiti" :key="`info-${index}`" class="dea-footer__links-list-item">
            <a :href="item.href" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <div class="dea-footer__links">
        <h4 class="dea-footer__links-title">{{ info.sectionTitles.terminiGenerali }}</h4>
        <ul class="dea-footer__links-list no-border-bottom">
          <li v-for="(item, index) in info.terminiGenerali" :key="`info-${index}`" class="dea-footer__links-list-item">
            <a :class="item.class" :href="item.href" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="dea-footer__container dea-footer__container--copyright">
      <div class="dea-footer__copyright">
        <div class="dea-footer__container--small">
          <p><span>D Scuola S.p.A.</span> {{ info.sectionTitles.copyright }} {{ year }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  props: {
    info: {
      type: Object,
      required: true,
    },
    showPiva: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss"></style>
